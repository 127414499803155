<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";



import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        
    },

    validations: {
       
    },
 

    data() {
      
        return {
            submitted : false,
          
        }
       
    },
    methods: {

        f(v, d = 2) {
            financial(v, d)
        },

        formSubmit() {
            this.$v.$touch()
          
            let data = {
                pm_id        : this.property.pm_id,
                rent         : this.property.monthly_rent,
                utility_fee  : this.property.utility_fee,
                other_income : this.property.other_income,
                parking      : this.property.parking
            }
            if (this.$v.$invalid == false) {
                getPMApi().update_rent(data).then(res =>{
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Update Rent  failed " + res.errCode);
                    }
                })
               
            }
            
        },
    },

    created() {
       
    },

    mounted() {
        console.log(this.property)
    },

    watch: {
       
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Montly  Rent</label>
                            <input class="form-control" v-model="property.monthly_rent" />
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom02">Parking</label>
                            <input class="form-control" v-model="property.parking"  />
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom02">Utilities</label>
                            <input class="form-control" v-model="property.utility_fee"  />
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom02">Other</label>
                            <input class="form-control" v-model="property.other_income"  />
                          
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>