<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";



import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property: {
            type: Object,
            default: () => {

            }
        }
    },
    components: {

    },

    validations: {

    },


    data() {

        return {
            submitted: false,
            owner_reserved   : '',
            security_deposit : '',
            available_balance: '',
            total_balance    : '',
            rent_in_hold     : 0,
        }

    },
    methods: {
        formSubmit() {
            this.$v.$touch()

            if (this.$v.$invalid == false) {
                getPMApi().transfer_balance({ pm_id: this.property.property_id, rent_in_hold: this.rent_in_hold, security_deposit : this.security_deposit, owner_reserved : this.owner_reserved }).then(res => {
                    if (res.errCode == 0) {
                        this.$emit('confirm', { pm_id: this.property.property_id, rent_in_hold: this.rent_in_hold, new_balance: this.available_balance, security_deposit : this.security_deposit, owner_reserved : this.owner_reserved  })
                    } else {
                        this.$alertify.error("Update Balance Hold failed " + res.errCode);
                    }
                })

            }

        },
    },

    created() {

    },

    mounted() {

        this.available_balance = financial(Number(this.property.available_balance), 2)
        this.owner_reserved    = financial(Number(this.property.owner_reserved), 2)
        this.security_deposit  = financial(Number(this.property.deposit), 2)
        this.total_balance     = financial(Number(this.property.balance_in_trust), 2)
    },

    watch: {
        owner_reserved(new_val) {
            let new_owner_reserved = financial(Number(new_val), 2)
            this.available_balance = this.total_balance - this.security_deposit- new_owner_reserved
            
        },

        security_deposit(new_val) {
            let new_security_deposit = financial(Number(new_val), 2)
            this.available_balance = this.total_balance - this.owner_reserved- new_security_deposit
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-1"></div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Available balance</label>
                            <input class="form-control" v-model="available_balance" readonly/>

                        </div>
                    </div>
                    
                    <div class="col-md-1 col-lg-1 col-sm-1 icon-demo-content mt-4">
                        <i class="uil-angle-double-right"></i>
                    </div>
                    <div class="col-md-6">

                        <div class="row">
                            <div class="col-md-8">
                                <div class="mb-3">
                                    <label for="validationCustom02">Security Despoit</label>
                                    <input class="form-control" v-model="security_deposit"  />

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-8">
                                <div class="mb-3">
                                    <label for="validationCustom02">Owner Reserve</label>
                                    <input class="form-control" v-model="owner_reserved"  />

                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>