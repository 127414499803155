<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import Choices from "choices.js";


export default {

    emits: ['confirm', 'cancel','onDelete'],

    props: {
        tenant : {
            type : Object,
            default: ()=> {
               return {
                t_payment        : '',
                t_name           : '',
                t_institution    : '',
                t_transit        : '',
                t_account_number : '',
                is_extra_tenant  : true,
                extra_tenant_id  : '',
                payment_amount   : '',
               }

            }
        },
        tenant_list: {
            type : Array,
            default: () => []
        },
    },
    components: {
        VueBootstrapTypeahead
    },

    validations: {
       
    },
 

    data() {
      
        return {
            submitted : false,
            payment_type_list :[
                {value : 'PAD', label : 'PAD', selected : false},
                {value : 'Cheque', label : 'Cheque', selected : false},
                {value : 'ETransfer', label : 'E-Transfer', selected : false},
            ],
            payment_type_choice: {}
        }
       
    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            console.log(this.tenant)
            if (this.$v.$invalid == false) {
                this.$emit('confirm', this.tenant)
            }
            
        },

        onTenantSelected(evt) {
            
            this.tenant.payment_amount   = evt.payment_amount
            this.tenant.t_payment        = evt.t_payment
            this.tenant.t_institution    = evt.t_institution
            this.tenant.t_transit        = evt.t_transit
            this.tenant.t_account_number = evt.t_account_number
            this.tenant.extra_tenant_id  = evt.extra_tenant_id
            this.tenant.is_extra_tenant  = evt.is_extra_tenant
            this.payment_type_list.map(e => e.selected = e.value == this.tenant.t_payment)

            this.payment_type_choice.clearChoices()
            this.payment_type_choice.setChoices(this.payment_type_list)
        },

        onRemove() {
            this.$alertify.confirmWithTitle(
                "Confirmation",
                "Are you sure to Delete this tenant?",
                () => {
                    this.$emit('onDelete', this.tenant)
                }
            )
        }
    },

    created() {
       
    },

    mounted() {
        this.payment_type_choice = new Choices("#tenant_payment_type", {
            removeItemButton: false,
            choices: this.payment_type_list
        });
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Tenant Name</label>
                            <vue-bootstrap-typeahead 
                              v-model="tenant.t_name"  :data="tenant_list"
                              :serializer="s =>s.t_name"
                              :foramterDisplay="s => s.t_name " @hit="onTenantSelected($event)" autocomplete="off" maxlength="22" />
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">PAD Payment</label>
                            <select id="tenant_payment_type" v-model="tenant.t_payment" class="form-control"> 
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Payment Amount</label>
                            <input type="text" class="form-control" v-model="tenant.payment_amount"  />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                        <input type="text" class="form-control" v-model="tenant.t_institution" placeholder="Enter Bank  Institution Number"  :disabled=" tenant.t_payment != 'PAD'"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                        <input type="text" class="form-control" v-model="tenant.t_transit" placeholder="Enter Bank Transit Number" :disabled=" tenant.t_payment != 'PAD'"
                           />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Account</label>
                        <input type="text" class="form-control" v-model="tenant.t_account_number" placeholder="Enter Bank Account" :disabled=" tenant.t_payment != 'PAD'"
                         />
                      </div>
                    </div>
                  </div>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                            <b-button  variant="danger" v-if="tenant_list.length > 1" type="button" @click="onRemove">Delete</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>