<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";


import Choices from "choices.js";
import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        
    },

    computed : {
        commission() {
           
            return this.f(Number(this.property.monthly_manager_commission))
        }
    },

    validations: {
       
    },
 

    data() {
      
        return {
            submitted : false,
          
        }
       
    },
    methods: {

        f(v, d = 2) {
            return financial(v, d)
        },

        formSubmit() {
            this.$v.$touch()
          
            let data = {
                pm_id        : this.property.pm_id,
                commission_pay_way         : this.property.monthly_manger_pay_way,
                monthly_manager_commission  : this.property.monthly_manager_commission,
            }
            if (this.$v.$invalid == false) {
                getPMApi().update_commission(data).then(res =>{
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Update Commission  failed " + res.errCode);
                    }
                })
               
            }
            
        },

    },

    created() {
       
    },

    mounted() {
        new Choices("#commission_type", {
            removeItemButton: false, 
            choices: [
                { label : 'Fixed',  value : 1,  selected : this.property.monthly_manger_pay_way == 1 ? true : false},
                { label : 'Rate',  value : 0, selected : this.property.monthly_manger_pay_way == 0 ? true : false}
            ]
        });
    },

    watch: {
       
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Commission  Type</label>
                            <select class="form-control" v-model="property.monthly_manger_pay_way" id="commission_type">
                              
                            </select>
                         
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom02">Commission Value <span for="validationCustom02"  v-if="property.monthly_manger_pay_way == 0">%</span></label>
                            <input class="form-control" v-model="property.monthly_manager_commission"  />
                          
                        </div>
                    </div>


                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>