<script>



import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";
import { getPMApi } from '@/api/pm'

import {
  required,
} from "vuelidate/lib/validators";



export default {

    emits: ['confirm', 'cancel'],

    props: {
        property_list : {
            type : Array,
            default: ()=> []
        }
    },
    components: {
        flatPickr
    },

    validations() {
       if (this.export_type =='Statement') {
            if (this.is_latest == true) {
                return {
                    start_date : {},
                    end_date : {required}
                }
            } else {
                return {
                    start_date : {required},
                    end_date : {required}
                }
            }
           
       } else {
            return {
                start_date : {},
                end_date : {},
            }
       }
    },
 

    data() {
      
        return {
            export_type : 'EndingBalance',
            start_date  : '',
            end_date    : '',
            is_exporting : false,
            export_failed : false,
            job_id      : '',
            is_latest   : true,
            all_flag    : false,
        }
       
    },
    methods: {
        onExportPdf() {

            this.$v.$touch()
            if (this.$v.$invalid == false) {
                let selected_list = []
                let colums = {
                    keys : ['address','t_payment','balance_in_trust'],
                    labels : ['Address', 'Tenant Payment', 'Ending Balance']
                }
                
                this.property_list.map(e => e.checked == true?selected_list.push(e.pm_id): '')
                let data = {
                    export_type     : this.export_type+"_Pdf",
                    property_list   : selected_list,
                    columns         : colums,
                    start_date      : this.start_date,
                    end_date        : this.end_date,
                    all_flag        : this.all_flag
                }


                if (this.export_type == 'Statement') {
                    this.is_exporting = true
                    getPMApi().export_statement_list(data).then(res =>{
                        if (res.errCode != 0) {
                            this.is_exporting = false
                            this.$alertify.error(" Export failed:" + res.errCode);
                        } else {
                            this.job_id = res.data.job.job_id
                            this.task_id = setInterval(this.queryProgress, 2000)
                        }
                    })
                } else {
                    getPMApi().export_pm(data).then(res =>{
                        if (res.errCode == 0) {
                            window.open(res.url, '_blank')
                            this.is_exporting = false
                        }
                    })
                }
               
            }
       },

       onExportExcel() {

            this.$v.$touch()
            if (this.$v.$invalid == false) {
                let selected_list = []
                let colums = {
                    keys : ['address','t_payment','balance_in_trust'],
                    labels : ['Address', 'Tenant Payment', 'Ending Balance']
                }
                
                this.property_list.map(e => e.checked == true?selected_list.push(e.pm_id): '')
                let data = {
                    export_type     : this.export_type+"_Excel",
                    property_list   : selected_list,
                    columns         : colums,
                    start_date      : this.start_date,
                    end_date        : this.end_date,
                    all_flag        : this.all_flag
                }
                getPMApi().export_pm(data).then(res =>{
                    if (res.errCode == 0) {
                        window.open(res.url, '_blank')
                        this.is_exporting = false
                    }
                })
            }
        },

        queryProgress() {
            getPMApi().query_export_job_status({job_id : this.job_id}).then(res => {
                if (res.errCode == 0) {
                    if (res.data.state =='FINISHED') {
                        clearInterval(this.task_id)
                        window.open(res.data.url, '_blank')
                        this.is_exporting = false
                    }
                } else {
                    clearInterval(this.task_id)
                    this.export_failed = true
                    this.is_exporting = false
                }
            })
        }

    },

    created() {
       
    },

    mounted() {
        new Choices("#export_type", {
            removeItemButton: false,
        });
    },

    destroyed() {
        clearInterval(this.task_id)
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                           
                            <select id="export_type" v-model="export_type"  class="form-control">
                                <option value="Statement" >Statement</option>
                                <option value="EndingBalance" selected>Ending Balance</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-md-2 mt-2" >
                        <label for="validationCustom01">ALL RTAs</label> 
                    </div>
                    <div class="col-md-1 mt-2" >
                        <input type="checkbox" class="form-check"   v-model="all_flag"/>
                    </div>

                    <div class="col-md-3 mt-2" v-if="export_type == 'EndingBalance'">
                        <label for="validationCustom01">Lastest Ending Balance</label> 
                    </div>
                    <div class="col-md-1 mt-2" v-if="export_type == 'EndingBalance'">
                        <input type="checkbox" class="form-check"  :checked="true" v-model="is_latest"/>
                    </div>

                   

                </div>
                <!-- end row-->


                <div class="row" v-if="export_type =='Statement' || is_latest == false">

                    <div class="col-md-4" v-if="export_type =='Statement'">
                        <div class="mb-3">
                            <label for="validationCustom01">Start Date</label>
                            <flat-pickr v-model="start_date" placeholder="Select a date" class="form-control"
                                    :class="{ 'is-invalid': $v.start_date.$error }"></flat-pickr>
                                <div v-if="$v.start_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.start_date.required">This value is required.</span>
                                </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">End Date</label>
                            <flat-pickr v-model="end_date" placeholder="Select a date" class="form-control"
                                    :class="{ 'is-invalid': $v.end_date.$error }"></flat-pickr>
                                <div v-if="$v.end_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.end_date.required">This value is required.</span>
                                </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row" v-if="is_exporting">
                    <b-alert show variant="danger" >
                        <b-spinner  variant="danger" label="Spinning" class="me-2" ></b-spinner>
                        Exporting, please waiting.....
                    </b-alert>
                </div>

                <div class="row" v-if="export_failed">
                    <b-alert show variant="danger" >
                         Export Failed, please retry
                    </b-alert>
                </div>
                

               
                <div class="row">
                    <div class="col-md-3">

                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Close</b-button>
                            <b-button  variant="primary" type="button" @click="onExportPdf" :disabled="is_exporting">Export PDF</b-button>
                            <b-button  variant="primary" type="button" @click="onExportExcel" :disabled="is_exporting">Export Excel</b-button>
                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>